<template>
    <div class="features-style-area pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-card with-black-color">
                        <div class="image">
                            <img src="../../assets/images/ico-landing-home/features/icon-1.png" alt="icon">
                        </div>
                        <h3>First and Decentrelazed</h3>
                        <p>Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-card with-black-color">
                        <div class="image">
                            <img src="../../assets/images/ico-landing-home/features/icon-2.png" alt="icon">
                        </div>
                        <h3>Blockchain Powered</h3>
                        <p>Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-card with-black-color">
                        <div class="image">
                            <img src="../../assets/images/ico-landing-home/features/icon-3.png" alt="icon">
                        </div>
                        <h3>Dual Token Ecosystem</h3>
                        <p>Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-features-card with-black-color">
                        <div class="image">
                            <img src="../../assets/images/ico-landing-home/features/icon-4.png" alt="icon">
                        </div>
                        <h3>Absolutely No Risk</h3>
                        <p>Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>