<template>
    <div>
        <ValueTrade />
        <NavbarThree class="with-black-color" />
        <MainBanner />
        <div class="background-with-black-color">
            <ICOAbout />
            <Features />
            <ICOChooseUs />
            <TokenDistribution />
            <Platforms />
            <Roadmap />
            <LeadershipTeam />
            <TeamMember />
            <IndustryPartner />
            <FAQ />
            <ContactUs />
            <FooterFour />
        </div>
    </div>
</template>

<script>
import ValueTrade from '../Layout/ValueTrade'
import NavbarThree from '../Layout/NavbarThree'
import MainBanner from '../HomeSix/MainBanner'
import ICOAbout from '../HomeSix/ICOAbout'
import Features from '../HomeSix/Features'
import ICOChooseUs from '../HomeSix/ICOChooseUs'
import TokenDistribution from '../HomeSix/TokenDistribution'
import Platforms from '../HomeSix/Platforms'
import Roadmap from '../HomeSix/Roadmap'
import LeadershipTeam from '../HomeSix/LeadershipTeam'
import TeamMember from '../HomeSix/TeamMember'
import IndustryPartner from '../HomeSix/IndustryPartner'
import FAQ from '../HomeSix/FAQ'
import ContactUs from '../HomeSix/ContactUs'
import FooterFour from '../Layout/FooterFour'

export default {
    name: 'HomePageSix',
    components: {
        ValueTrade,
        NavbarThree,
        MainBanner,
        ICOAbout,
        Features,
        ICOChooseUs,
        TokenDistribution,
        Platforms,
        Roadmap,
        LeadershipTeam,
        TeamMember,
        IndustryPartner,
        FAQ,
        ContactUs,
        FooterFour,
    }
}
</script>