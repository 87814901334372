<template>
    <div class="case-studies-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR CASE STUDIES</span>
                <h2>We Get you Higher Revenues, Not just Higher Ranks</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="case-studies-list">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6">
                        <div class="single-case-studies-item bg-3D2F5D">
                            <img src="../../assets/images/case-studies/case-studies1.png" alt="image">
                            <h3><router-link to="/work-details">eLan Education</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua. Ut enim ad minim veniam, quis norud.</p>
                            <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                        <div class="single-case-studies-item bg-AC5863">
                            <img src="../../assets/images/case-studies/case-studies2.png" alt="image">
                            <h3><router-link to="/work-details">StartP Startup</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua. Ut enim ad minim veniam, quis norud.</p>
                            <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6">
                        <div class="single-case-studies-item bg-FFB774">
                            <img src="../../assets/images/case-studies/case-studies3.png" alt="image">
                            <h3><router-link to="/work-details">Edmo Marketing</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua. Ut enim ad minim veniam, quis norud.</p>
                            <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                        <div class="single-case-studies-item bg-34575A">
                            <img src="../../assets/images/case-studies/case-studies1.png" alt="image">
                            <h3><router-link to="/work-details">WideCademy Remote</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua. Ut enim ad minim veniam, quis norud.</p>
                            <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape6"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'WeGetYouHigherRevenuesTwo'
}
</script>