<template>
    <div class="team-member-area pt-100 pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Our Team Member</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="team-member-card">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-1.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Larry Hofmann</h3>
                            <span>Technology Officer</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="team-member-card">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-2.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Gail Douthitt</h3>
                            <span>Businesss Advisor</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="team-member-card">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-3.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Patricia Conlon</h3>
                            <span>Creative Director</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="team-member-card">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-4.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Danette Rushing</h3>
                            <span>Executive Officer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="team-member-shape">
            <img src="../../assets/images/cybersecurity-home/team/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamMember'
}
</script>