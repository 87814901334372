<template>
    <div class="team-area pt-100 pb-70">
        <div class="container">
            <div class="section-title style-two">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <span class="sub-title">OUR EXPERTS</span>
                        <h2>Meet Our Leadership <br>Preparing For Your Success</h2>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters. It is a long established fact that a reader will be distracted by the.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-1"></div>
                        </div>
                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>Marketing Lead</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-2"></div>
                        </div>
                        <div class="content">
                            <h3>Ben Stokes</h3>
                            <span>Art Director</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-3"></div>
                        </div>
                        <div class="content">
                            <h3>Ellyse Perry</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-4"></div>
                        </div>
                        <div class="content">
                            <h3>Steven Smith</h3>
                            <span>Development</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-5"></div>
                        </div>
                        <div class="content">
                            <h3>Suzie Bates</h3>
                            <span>Front-End Development</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-6"></div>
                        </div>
                        <div class="content">
                            <h3>David Warner</h3>
                            <span>Head of Team</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-7"></div>
                        </div>
                        <div class="content">
                            <h3>Ross Taylor</h3>
                            <span>Front-End Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-8"></div>
                        </div>
                        <div class="content">
                            <h3>Meg Lanning</h3>
                            <span>Art Director</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9"></div>
                        </div>
                        <div class="content">
                            <h3>Du Plessis</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-10"></div>
                        </div>
                        <div class="content">
                            <h3>Ellyse Perry</h3>
                            <span>Development</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-11"></div>
                        </div>
                        <div class="content">
                            <h3>Kagiso Steyn</h3>
                            <span>Front-End Development</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MeetOurLeadershipPreparing'
}
</script>