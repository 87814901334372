<template>
    <div>
        <Header />
        <PageTitleTwo />
        <WeGetYouHigherRevenuesTwo />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitleTwo from '../WorkOne/PageTitleTwo'
import WeGetYouHigherRevenuesTwo from '../Common/WeGetYouHigherRevenuesTwo'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitleTwo,
        WeGetYouHigherRevenuesTwo,
        LetsTalkSomething,
    }
}
</script>