<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-about-image">
                        <img src="../../assets/images/cryptocurrency-home/about.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-about-content">
                        <span class="sub-title">About Crypto</span>
                        <h2>New Investors Can Buy The Initial Currency Offer To Receive A Cryptocurrency Token</h2>
                        <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Donec rutrum congue leo eget malesuada. Quisque velit nisi pretium ut lacinia in elementum.</p>
                        <ul class="about-list">
                            <li><i class="ri-check-fill"></i> Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</li>
                            <li><i class="ri-check-fill"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim. </li>
                        </ul>
                        <div class="about-btn">
                            <router-link to="/contact" class="default-btn style-two">
                                <span class="ri-arrow-right-s-line"></span>
                                Learn More
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CryptocurrencyAbout'
}
</script>