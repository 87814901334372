<template>
    <div class="blog-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR LATEST BLOG</span>
                <h2>Our Latest Insights Are On Top All Times</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog1.jpg" alt="image">
                            </router-link>
                            <span class="date">March 20, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Resources for Teachers</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog2.jpg" alt="image">
                            </router-link>
                            <span class="date">March 19, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Instructional Strategies</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog3.jpg" alt="image">
                            </router-link>
                            <span class="date">March 18, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Fitness Guideline Program</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurLatestInsights'
}
</script>