<template>
    <div class="awards-list">
        <h4>AWARDS AND INDUSTRY RECOGNITION</h4>
        <div class="row justify-content-center">
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards4.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards5.png" alt="image">
                </div>
            </div>
            <div class="col-lg-2 col-4 col-sm-4 col-md-3">
                <div class="awards-box">
                    <img src="../../assets/images/awards/awards6.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AwardsAndIndustry'
}
</script>