<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content text-center">
                <h1>Contact Info</h1>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-map-pin-2-line"></i>
                            </div>
                            <h3>Address:</h3>
                            <p>2750 Open Space, Quadra Street Line Victoria, Canada</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-time-line"></i>
                            </div>
                            <h3>Working Hours:</h3>
                            <p>
                                Open Saturday to Friday
                                <br>
                                From 06:00 PM to 20:00 AM
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-mail-unread-line"></i>
                            </div>
                            <h3>Email Address:</h3>
                            <p>
                                <a href="mailto:hello@river.com">hello@river.com</a>
                                <br>
                                <a href="mailto:info@river.com">info@river.com</a>
                            </p>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class="ri-phone-line"></i>
                            </div>
                            <h3>Toll Free:</h3>
                            <p>
                                <a href="tel:+44587154756">+44 587 154756</a>
                                <br>
                                <a href="tel:+44587154756">+55 58715 4756</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>