<template>
    <div>
        <TopHeader />
        <NavbarThree />
        <MainBanner />
        <Features />
        <WhatWeWork />
        <Funfacts />
        <CyberSecurityServices />
        <Video />
        <WhyChoose />
        <IndustryPartner />
        <Pricing />
        <GetInTouch />
        <TeamMember />
        <CyberSafety />
        <Blog />
        <FooterTwo />
    </div>
</template>

<script>
import TopHeader from '../Layout/TopHeader'
import NavbarThree from '../Layout/NavbarThree'
import MainBanner from '../HomeFour/MainBanner'
import Features from '../HomeFour/Features'
import WhatWeWork from '../HomeFour/WhatWeWork'
import Funfacts from '../HomeFour/Funfacts'
import CyberSecurityServices from '../HomeFour/CyberSecurityServices'
import Video from '../HomeFour/Video'
import WhyChoose from '../HomeFour/WhyChoose'
import IndustryPartner from '../HomeFour/IndustryPartner'
import Pricing from '../HomeFour/Pricing'
import GetInTouch from '../HomeFour/GetInTouch'
import TeamMember from '../HomeFour/TeamMember'
import CyberSafety from '../HomeFour/CyberSafety'
import Blog from '../HomeFour/Blog'
import FooterTwo from '../Layout/FooterTwo'

export default {
    name: 'HomePageFour',
    components: {
        TopHeader,
        NavbarThree,
        MainBanner,
        Features,
        WhatWeWork,
        Funfacts,
        CyberSecurityServices,
        Video,
        WhyChoose,
        IndustryPartner,
        Pricing,
        GetInTouch,
        TeamMember,
        CyberSafety,
        Blog,
        FooterTwo,
    }
}
</script>