<template>
    <div class="cryptocurrency-choose-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-choose-image">
                        <img src="../../assets/images/cryptocurrency-home/choose/choose.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-choose-content">
                        <span class="sub-title">Why Choose Crypto</span>
                        <h2>Investors In Icos Expect To Gain Value From Tokens</h2>

                        <div class="choose-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/choose/image-1.png" alt="image">
                            </div>
                            <h3>Full Interoperability</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="choose-inner-box with-some-left">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/choose/image-2.png" alt="image">
                            </div>
                            <h3>Contract Level Security</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="choose-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/choose/image-3.png" alt="image">
                            </div>
                            <h3>Live Human Support</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CryptocurrencyChoose'
}
</script>