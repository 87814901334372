<template>
    <footer class="footer-style-wrap-area pt-100">
        <div class="container">
            <div class="footer-widget-style-wrap with-white-color">
                <router-link to="/" class="d-inline-block logo">
                    <img src="../../assets/images/white-logo.png" alt="logo">
                </router-link>
                <ul class="quick-links">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about-us">About Us</router-link></li>
                    <li><router-link to="/work-style-one">Case Studies</router-link></li>
                    <li><router-link to="/blog">Our News</router-link></li>
                    <li><router-link to="/contact">Contact Us</router-link></li>
                    <li><router-link to="/pricing">Pricing</router-link></li>
                </ul>
                <ul class="social-links">
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-facebook-fill"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-twitter-fill"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-linkedin-fill"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-instagram-line"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-pinterest-line"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-skype-line"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-youtube-line"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-github-fill"></i></a>
                    </li>
                    <li><a href="https://facebook.com/" target="_blank">
                        <i class="ri-gitlab-fill"></i></a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="copyright-area with-white-color">
            <div class="container">
                <p>Copyright {{currentYear}} <strong>River</strong>. All Rights Reserved by <a href="https://7midia.com/" target="_blank">7midia</a></p>
            </div>
        </div>

        <div class="footer-style-wrap-shape">
            <img src="../../assets/images/ico-landing-home/global-shape.png" alt="image">
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterFour',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>