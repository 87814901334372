<template>
    <div class="case-studies-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR CASE STUDIES</span>
                <h2>We Get you Higher Revenues, Not just Higher Ranks</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="case-studies-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-case-studies-box">
                            <div class="row align-items-center m-0">
                                <div class="col-lg-6 col-md-12 p-0">
                                    <div class="content">
                                        <div class="logo">
                                            <img :src="slide.logo" alt="image">
                                        </div>
                                        <h3>
                                            <router-link :to="slide.link">
                                                {{slide.title}}
                                            </router-link>
                                        </h3>
                                        <p>{{slide.desc}}</p>
                                        <ul class="features-list">
                                            <li
                                                v-for="features in slide.featuresList"
                                                :key="features.id"
                                            >
                                                <span>{{features.feature}}</span>
                                                <router-link :to="features.link">
                                                    {{features.linkText}}
                                                </router-link>
                                            </li>
                                        </ul>
                                        <router-link :to="slide.link" class="default-btn">
                                            <span class="ri-arrow-right-s-line"></span>
                                            {{slide.btnText}}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 p-0">
                                    <div class="image">
                                        <img :src="slide.image" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
        <div class="shape7"><img src="../../assets/images/shape/shape5.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'WeGetYouHigherRevenues',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                logo: require('../../assets/images/case-studies/elan.png'),
                title: 'eLan Education',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
                featuresList: [
                    {
                        id: 1,
                        feature: 'FRAMEWORK',
                        link: '/work-style-one',
                        linkText: 'WordPress',
                    },
                    {
                        id: 2,
                        feature: 'CATEGORY',
                        link: '/work-style-two',
                        linkText: 'Education',
                    },
                    {
                        id: 3,
                        feature: 'WEB LINK',
                        link: '#',
                        linkText: 'www.elan.com',
                    },
                ],
                btnText: 'View More',
                link: '/work-details',
                image: require('../../assets/images/case-studies/case-studies1.png'),
            },
            {
                id: 2,
                logo: require('../../assets/images/case-studies/startp.png'),
                title: 'StartP Startup',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
                featuresList: [
                    {
                        id: 1,
                        feature: 'FRAMEWORK',
                        link: '/work-style-two',
                        linkText: 'React.js',
                    },
                    {
                        id: 2,
                        feature: 'CATEGORY',
                        link: '/work-style-three',
                        linkText: 'Startup',
                    },
                    {
                        id: 3,
                        feature: 'WEB LINK',
                        link: '#',
                        linkText: 'www.startp.com',
                    },
                ],
                btnText: 'View More',
                link: '/work-details',
                image: require('../../assets/images/case-studies/case-studies2.png'),
            },
            {
                id: 3,
                logo: require('../../assets/images/case-studies/edmo.png'),
                title: 'Edmo Marketing',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
                featuresList: [
                    {
                        id: 1,
                        feature: 'FRAMEWORK',
                        link: '/work-style-one',
                        linkText: 'Moodle',
                    },
                    {
                        id: 2,
                        feature: 'CATEGORY',
                        link: '/work-style-two',
                        linkText: 'Marketing',
                    },
                    {
                        id: 3,
                        feature: 'WEB LINK',
                        link: '#',
                        linkText: 'www.edmo.com',
                    },
                ],
                btnText: 'View More',
                link: '/work-details',
                image: require('../../assets/images/case-studies/case-studies3.png'),
            },
        ],
    }),
})
</script>