<template>
    <div class="plans-compare-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">COMPARE PLANS</span>
                    <h2>Plans For Everything, Plans Details</h2>
                </div>

                <div class="plans-compare-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Features</th>
                                <th scope="col">Free</th>
                                <th scope="col">Starter</th>
                                <th scope="col">Team</th>
                                <th scope="col">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Seats</td>
                                <td>1</td>
                                <td>1</td>
                                <td>3+</td>
                                <td>10+</td>
                            </tr>
                            <tr>
                                <td>Active projects</td>
                                <td>1</td>
                                <td>3</td>
                                <td>10</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Viewers</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>No. of blocks</td>
                                <td>10</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Conditions</td>
                                <td>-</td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                            </tr>
                            <tr>
                                <td>CSV export</td>
                                <td>-</td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                            </tr>
                            <tr>
                                <td>Password protection</td>
                                <td>-</td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                            </tr>
                            <tr>
                                <td>Team management</td>
                                <td>-</td>
                                <td>-</td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                            </tr>
                            <tr>
                                <td>Customization</td>
                                <td>-</td>
                                <td>-</td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                                <td><i class="ri-checkbox-circle-line"></i></td>
                            </tr>
                            <tr>
                                <td>Legal Block</td>
                                <td>Add-On</td>
                                <td>Add-On</td>
                                <td>Add-On</td>
                                <td>Add-On</td>
                            </tr>
                            <tr>
                                <td>Dedicated CSM</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td><router-link to="/contact">Get A Quote</router-link></td>
                            </tr>
                            <tr>
                                <td>Dedicated onboarding</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td><router-link to="/contact">Get A Quote</router-link></td>
                            </tr>
                            <tr>
                                <td>Priority support</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td><router-link to="/contact">Get A Quote</router-link></td>
                            </tr>
                            <tr>
                                <td>Custom payment terms</td>
                                <td>Add-On</td>
                                <td>-</td>
                                <td>-</td>
                                <td><router-link to="/contact">Get A Quote</router-link></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Need more information? <router-link to="/contact">Contact Us</router-link></td>
                                <td><router-link to="/contact" class="link-btn">Get Started</router-link></td>
                                <td><router-link to="/contact" class="link-btn">Get Started</router-link></td>
                                <td><router-link to="/contact" class="link-btn">Get Started</router-link></td>
                                <td><router-link to="/contact" class="link-btn">Get Started</router-link></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'PlansForEverything'
}
</script>