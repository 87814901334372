<template>
    <div class="token-distribution-area pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Token Distribution</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="token-distribution-card">
                        <ul class="distribution-list">
                            <li>
                                <span class="bg-0EA6EC">45%</span> Initial Coin Offering
                            </li>
                            <li>
                                <span class="bg-F07258">15%</span> Incentivisation use Platform
                            </li>
                            <li>
                                <span class="bg-0AA350">10%</span> Private Investors
                            </li>
                            <li>
                                <span class="bg-EACF2A">15%</span> Business Development
                            </li>
                            <li>
                                <span class="bg-F48A1D">10%</span> Marketing & Bounty
                            </li>
                            <li>
                                <span class="bg-D72E7C">5%</span> Team Members
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="token-distribution-card">
                        <img src="../../assets/images/donut-chart.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="token-distribution-card">
                        <div class="token-distribution-details">
                            <div class="header">
                                <h3>Coin Details</h3>
                            </div>
                            <ul class="info-list">
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Asset Name</span>
                                    <span>River Coin</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Ticket</span>
                                    <span>River</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Starting Price</span>
                                    <span>$1.00</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Coin Type</span>
                                    <span>Hard Cap</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Intital Supply</span>
                                    <span>50K</span>
                                </li>
                                <li class="d-flex justify-content-between align-items-center">
                                    <span>Max Supply</span>
                                    <span>50M</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TokenDistribution'
}
</script>