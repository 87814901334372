<template>
    <div class="why-choose-us-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="why-choose-us-content">
                        <span class="sub-title">Why Choose Us</span>
                        <h2>You Can Choose Us By Looking At Our Different Work Skills</h2>
                        <p>Donec sollicitudin molestie malesuada vivamus magna justo lacinia eget consectetur sed convallis at tellus donec rutrum congue leo eget malesuada quisque velit nisi pretium ut lacinia in elementum.</p>

                        <ul class="list">
                            <li><i class="ri-check-fill"></i> Network and system administration</li>
                            <li><i class="ri-check-fill"></i> Operating system and virtual machine</li>
                            <li><i class="ri-check-fill"></i> Network security control</li>
                            <li><i class="ri-check-fill"></i> Coding languages</li>
                            <li><i class="ri-check-fill"></i> Cloud security</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="why-choose-us-image">
                        <img src="../../assets/images/cybersecurity-home/choose/choose.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="why-choose-us-shape">
            <img src="../../assets/images/cybersecurity-home/choose/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChoose'
}
</script>