<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">FAQ</span>
        <h2>Frequently Asked Questions</h2>
      </div>
      <div class="faq-accordion">
        <accordion>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">What is Team Management?</button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Can I cancel my plan at any time?
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Can I transfer projects from my personal account to my team?
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Do you offer a discount for universities and students?
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                What are my payment options?
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.
              </p>
            </template>
          </accordion-item>
        </accordion>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: "FrequentlyAskedQuestions",
    components: {
        Accordion,
        AccordionItem,
    },
};
</script>
