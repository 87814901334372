<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Case Studies (Default)" pageTitle="Case Studies" />
        <WeGetYouHigherRevenues class="pb-0" />
        <ATrustedPartner class="pb-100" />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import WeGetYouHigherRevenues from '../Common/WeGetYouHigherRevenues'
import ATrustedPartner from '../Common/ATrustedPartner'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitle,
        WeGetYouHigherRevenues,
        ATrustedPartner,
        LetsTalkSomething,
    }
}
</script>