<template>
    <div class="funfacts-style-area">
        <div class="container">
            <div class="funfacts-style-inner-box pt-100 pb-70">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                        <div class="single-funfact-card">
                            <h3>5K</h3>
                            <p>Data Secure</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                        <div class="single-funfact-card">
                            <h3>1500+</h3>
                            <p>Trusted Organizations</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                        <div class="single-funfact-card">
                            <h3>100%</h3>
                            <p>Service Guarantee</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                        <div class="single-funfact-card">
                            <h3>100+</h3>
                            <p>Website Protection</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>