<template>
    <div class="case-studies-area extra-top-padding bg-gradient-image pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR CASE STUDIES</span>
                <h2>Case Studies</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br>incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="shorting-menu">
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'all'}" 
                    v-on:click="itemFilterKey = 'all'" 
                >
                    All
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'development'}"
                    v-on:click="itemFilterKey = 'development'"
                >
                    Development
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'design'}"
                    v-on:click="itemFilterKey = 'design'"
                >
                    Design
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'marketing'}"
                    v-on:click="itemFilterKey = 'marketing'"
                >
                    Marketing
                </button>
            </div>
            <div class="shorting row justify-content-center">
                <div 
                    v-for="item in itemFilter"
                    :key="item.id"
                    class="col-lg-4 col-sm-6 col-md-6 mix development"
                >
                    <div class="case-studies-box">
                        <img :src="item.img" alt="image">
                        <h3><router-link to="/work-details">{{item.title}}</router-link></h3>
                        <p>{{item.text}}</p>
                        <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>{{item.readMore}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseStudies',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    img: require('../../assets/images/case-studies/case-studies4.png'),
                    title: 'eLan Education',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'development'
                },
                {
                    id: 2,
                    img: require('../../assets/images/case-studies/case-studies5.png'),
                    title: 'StartP Startup',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'development'
                },
                {
                    id: 3,
                    img: require('../../assets/images/case-studies/case-studies6.png'),
                    title: 'Edmo Marketing',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'design'
                },
                {
                    id: 4,
                    img: require('../../assets/images/case-studies/case-studies7.png'),
                    title: 'StartNext IT',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'design'
                },
                {
                    id: 5,
                    img: require('../../assets/images/case-studies/case-studies8.png'),
                    title: 'Naxai Security',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'marketing'
                },
                {
                    id: 6,
                    img: require('../../assets/images/case-studies/case-studies9.png'),
                    title: 'Noke Creative',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore rts magna rtt aliqua.',
                    readMore: 'Read More',
                    key: 'marketing'
                },
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        development(){
            return this.items.filter((item) => item.key == 'development')
        },
        design(){
            return this.items.filter((item) => item.key == 'design')
        },
        marketing(){
            return this.items.filter((item) => item.key == 'marketing')
        },
    }
}
</script>