<template>
    <div>
        <Header />
        <CaseStudies />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import CaseStudies from '../WorkThree/CaseStudies'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        CaseStudies,
        LetsTalkSomething
    }
}
</script>