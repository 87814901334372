<template>
    <div class="team-member-area pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Our Leadership</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="team-member-card with-white-color">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-5.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Michael Latshaw</h3>
                            <span>Deputy CEO & Co-founder</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="team-member-card with-white-color">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-6.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Joseph Heckman</h3>
                            <span>CIO & Co-founder</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="team-member-card with-white-color">
                        <div class="team-image">
                            <img src="../../assets/images/cybersecurity-home/team/team-7.jpg" alt="image">

                            <div class="social-btn">
                                <span><i class="ri-share-line"></i></span>

                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/envato" target="_blank">
                                            <i class="ri-facebook-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-content">
                            <h3>Kenneth Coleman</h3>
                            <span>Chief Product Officer & Co-founder</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeadershipTeam'
}
</script>