<template>
    <div class="funfacts-area pt-100 pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <i class="ri-stack-fill"></i>
                        </div>
                        <h3>
                            <span class="odometer">355</span>
                        </h3>
                        <p>COMPLETED PROJECT</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon bg-4D69FF">
                            <i class="ri-user-smile-fill"></i>
                        </div>
                        <h3>
                            <span class="odometer">289</span>
                        </h3>
                        <p>HAPPY CUSTOMERS</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon bg-FFC23B">
                            <i class="ri-medal-fill"></i>
                        </div>
                        <h3>
                            <span class="odometer">15</span>
                        </h3>
                        <p>WINNING AWARD</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-4 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon bg-00A4C8">
                            <i class="ri-group-fill"></i>
                        </div>
                        <h3>
                            <span class="odometer">00</span>
                        </h3>
                        <p>TEAM MEMBER</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>