<template>
    <div>
        <Header />
        <ContactInfo />
        <GetInTouch />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import ContactInfo from '../Contact/ContactInfo'
import GetInTouch from '../Contact/GetInTouch'

export default {
    components: {
        Header,
        ContactInfo,
        GetInTouch,
    }
}
</script>