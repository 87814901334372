<template>
    <div>
        <Header />
        <LetsTalkSomethingAmazing />
        <ListsOfSocialMarketing />
        <ATrustedBrands />
        <WeGetYouHigherRevenuesTwo />
        <WhatOurClients />
        <HowWeWorks />
        <ContentWritingPricing pricingTitle="Social Marketing Pricing Free" />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import LetsTalkSomethingAmazing from '../ServicesTwo/LetsTalkSomethingAmazing'
import ListsOfSocialMarketing from '../ServicesTwo/ListsOfSocialMarketing'
import ATrustedBrands from '../ServicesTwo/ATrustedBrands'
import WeGetYouHigherRevenuesTwo from '../Common/WeGetYouHigherRevenuesTwo'
import WhatOurClients from '../Common/WhatOurClients'
import HowWeWorks from '../Common/HowWeWorks'
import ContentWritingPricing from '../Common/ContentWritingPricing'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        LetsTalkSomethingAmazing,
        ListsOfSocialMarketing,
        ATrustedBrands,
        WeGetYouHigherRevenuesTwo,
        WhatOurClients,
        HowWeWorks,
        ContentWritingPricing,
        LetsTalkSomething,
    }
}
</script>