<template>
    <div class="home-area">
        <div class="home-slides">
            <carousel 
                :autoplay="5000"
                :settings="settings"
            >
                <slide 
                    v-for="slide in carouselItems" 
                    :key="slide.id"
                >
                    <div class="banner-item">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="home-content">
                                        <span class="sub-title">AWARD WINNING COMPANY</span>
                                        <h1>Make Your Business More Money With Team Of Experts</h1>
                                        <router-link to="/contact" class="default-btn style-two">
                                            <span class="ri-arrow-right-s-line"></span>
                                            Get Started Now
                                        </router-link>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="home-image">
                                        <img src="../../assets/images/banner-img1.png" alt="banner-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="banner-item">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="home-content">
                                        <span class="sub-title">AWARD WINNING COMPANY</span>
                                        <h1>Make Your Business More Money With Team Of Experts</h1>
                                        <router-link to="/contact" class="default-btn style-two">
                                            <span class="ri-arrow-right-s-line"></span>
                                            Get Started Now
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="home-image">
                                        <img src="../../assets/images/banner-img2.png" alt="banner-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>

                <template #addons>
                    <navigation>
                        <template #next>
                            <i class='ri-arrow-right-s-line'></i>
                        </template>
                        <template #prev>
                            <i class='ri-arrow-left-s-line'></i>
                        </template>
                    </navigation>
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MeetOurLeadership',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                subTitle: 'AWARD WINNING COMPANY',
                heading: 'Make Your Business More Money With Team Of Experts',
                btnText: 'Get Started Now',
                btnLink: '/contact',
                image: require('../../assets/images/banner-img1.png')
            },
            {
                id: 2,
                subTitle: 'AWARD WINNING COMPANY',
                heading: 'Make Your Business More Money With Team Of Experts',
                btnText: 'Get Started Now',
                btnLink: '/contact',
                image: require('../../assets/images/banner-img2.png')
            },
        ],
    }),
})
</script>