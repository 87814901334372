<template>
    <div>
        <Header />
        <TechnicalSEOMarketing />
        <YouMayAlsoLike />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import TechnicalSEOMarketing from '../BlogDetails/TechnicalSEOMarketing'
import YouMayAlsoLike from '../BlogDetails/YouMayAlsoLike'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        TechnicalSEOMarketing,
        YouMayAlsoLike,
        LetsTalkSomething,
    }
}
</script>