<template>
    <div class="top-header-black-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-sm-7">
                    <div class="top-header-black-info">
                        <li>
                            <i class="ri-phone-line"></i>
                            <a href="tel:15143125678">+1 (514) 312-5678</a>
                        </li>
                        <li>
                            <i class="ri-mail-line"></i>
                            <a href="mailto:hello@river.com">hello@river.com</a>
                        </li>
                    </div>
                </div>

                <div class="col-lg-5 col-sm-5">
                    <ul class="top-header-black-social-links">
                        <li>
                            <a href="https://facebook.com/" target="_blank">
                                <i class="ri-facebook-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://linkedin.com/" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/" target="_blank">
                                <i class="ri-instagram-line"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopHeader'
}
</script>