<template>
    <div class="case-studies-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">CASE STUDIES</span>
                <h2>Related Case Studies</h2>
            </div>
            
            <div class="single-case-studies-box">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="content">
                            <div class="logo">
                                <img src="../../assets/images/case-studies/startp.png" alt="image">
                            </div>
                            <h3><router-link to="/work-details">StartP Startup</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                            <ul class="features-list">
                                <li>
                                    <span>FRAMEWORK</span>
                                    <a href="https://reactjs.org/" target="_blank">React.js</a>
                                </li>
                                <li>
                                    <span>CATEGORY</span>
                                    <router-link to="/work-style-three">Startup</router-link>
                                </li>
                                <li>
                                    <span>WEB LINK</span>
                                    <a href="#" target="_blank">www.startp.com</a>
                                </li>
                            </ul>
                            <router-link to="/work-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>View More</router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="image">
                            <img src="../../assets/images/case-studies/case-studies2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape7"><img src="../../assets/images/shape/shape5.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'RelatedCaseStudies'
}
</script>