<template>
    <div class="cryptocurrency-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-banner-content">
                        <h1>Launch Your Own Cryptocurrency</h1>
                        <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Donec rutrum congue leo eget malesuada.</p>

                        <ul class="banner-btn-list">
                            <li>
                                <router-link to="/contact" class="default-btn style-two">
                                    <span class="ri-arrow-right-s-line"></span>
                                    Learn More
                                </router-link>
                            </li>
                            <li>
                                <a href="#" class="white-paper-btn">White Paper</a>
                            </li>
                        </ul>

                        <ul class="banner-community">
                            <span>Join Our Community</span>

                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cryptocurrency-banner-image">
                        <img src="../../assets/images/cryptocurrency-home/banner/banner.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="cryptocurrency-banner-shape-1">
            <img src="../../assets/images/cryptocurrency-home/banner/shape-1.png" alt="image">
        </div>
        <div class="cryptocurrency-banner-shape-2">
            <img src="../../assets/images/cryptocurrency-home/banner/shape-2.png" alt="image">
        </div>
        <div class="cryptocurrency-banner-shape-3">
            <img src="../../assets/images/cryptocurrency-home/banner/shape-3.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>