<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog1.jpg" alt="image">
                            </router-link>
                            <span class="date">March 20, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Resources for Teachers</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog4.jpg" alt="image">
                            </router-link>
                            <span class="date">March 19, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Instructional Strategies</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="image">
                            </router-link>
                            <span class="date">March 18, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Fitness Guideline Program</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog6.jpg" alt="image">
                            </router-link>
                            <span class="date">March 17, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Content Writing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog7.jpg" alt="image">
                            </router-link>
                            <span class="date">March 16, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Social Marketing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog8.jpg" alt="image">
                            </router-link>
                            <span class="date">March 15, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Video Marketing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <span class="page-numbers current">1</span>
                            <a href="#" class="page-numbers">2</a>
                            <a href="#" class="page-numbers">3</a>
                            <a href="#" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogContet'
}
</script>