<template>
    <div>
        <div class="industry-partner-area pt-100 pb-70">
            <div class="container">
                <div class="section-title with-max-width">
                    <h2>Industry Partners</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="partner-slides">
                    <carousel
                        :autoplay="5000"
                        :settings='settings'
                        :breakpoints='breakpoints'
                    >
                        <slide 
                            v-for="slide in carouselItems" 
                            :key="slide.id"
                        >
                            <div class="partner-card-box">
                                <div class="d-block">
                                    <img :src="slide.image" alt="image">
                                </div>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>

        <div class="industry-partner-area">
            <div class="container">
                <div class="section-title with-max-width">
                    <h2>Investors</h2>
                </div>
                
                <div class="industry-partner-inner-box-wrap pt-100 pb-70">
                    <div class="row align-items-center">
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-13.png" alt="image">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-14.png" alt="image">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-15.png" alt="image">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-16.png" alt="image">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-17.png" alt="image">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                            <div class="partner-card">
                                <div class="d-block">
                                    <img src="../../assets/images/cybersecurity-home/partner/partner-18.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'IndustryPartner',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/cybersecurity-home/partner/partner-7.png'),
            },
            {
                id: 2,
                image: require('../../assets/images/cybersecurity-home/partner/partner-8.png'),
            },
            {
                id: 3,
                image: require('../../assets/images/cybersecurity-home/partner/partner-9.png'),
            },
            {
                id: 4,
                image: require('../../assets/images/cybersecurity-home/partner/partner-10.png'),
            },
            {
                id: 5,
                image: require('../../assets/images/cybersecurity-home/partner/partner-11.png'),
            },
            {
                id: 6,
                image: require('../../assets/images/cybersecurity-home/partner/partner-12.png'),
            },
            {
                id: 7,
                image: require('../../assets/images/cybersecurity-home/partner/partner-8.png'),
            },
            {
                id: 8,
                image: require('../../assets/images/cybersecurity-home/partner/partner-9.png'),
            },
            {
                id: 9,
                image: require('../../assets/images/cybersecurity-home/partner/partner-10.png'),
            },
            {
                id: 10,
                image: require('../../assets/images/cybersecurity-home/partner/partner-11.png'),
            },
            {
                id: 11,
                image: require('../../assets/images/cybersecurity-home/partner/partner-12.png'),
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
			},
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 6,
                snapAlign: 'center',
            },
        },
    }),
})
</script>