<template>
    <div class="banner-area">
        <div class="container">
            <div class="banner-content">
                <span class="sub-title">AWARD WINNING COMPANY</span>
                <h1>Make Your Business More Money With Team Of Experts</h1>
                <router-link to="/contact" class="default-btn style-two">
                    <span class="ri-arrow-right-s-line"></span>
                    Get Started Now
                </router-link>
                <img src="../../assets/images/banner.png" alt="banner-image">
            </div>
        </div>
        <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape2"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape3.png" alt="image"></div>
        <div class="shape4"><img src="../../assets/images/shape/shape4.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/images/shape/shape8.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>