<template>
    <div class="blog-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">RELATED BLOG</span>
                <h2>You May Also Like</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog6.jpg" alt="image">
                            </router-link>
                            <span class="date">March 17, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Content Writing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog7.jpg" alt="image">
                            </router-link>
                            <span class="date">March 16, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Social Marketing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/blog-details" class="d-block">
                                <img src="../../assets/images/blog/blog8.jpg" alt="image">
                            </router-link>
                            <span class="date">March 15, 2022</span>
                        </div>
                        <div class="content">
                            <h3><router-link to="/blog-details">Video Marketing Resources</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            <router-link to="/blog-details" class="default-btn"><span class="ri-arrow-right-s-line"></span>Read More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YouMayAlsoLike'
}
</script>