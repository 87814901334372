<template>
    <div class="quote-area">
        <div class="container">
            <div class="quote-content">
                <span class="sub-title">QUOTE</span>
                <h2>Free SEO Quotes Now</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <form>
                    <div class="row">
                        <div class="col-lg-4 col-md-3 pr-0">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your website URL">
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 pl-0">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Enter your email address">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3">
                            <button type="submit">Get Report <i class="ri-arrow-right-s-line"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="shape11">
            <img src="../../assets/images/shape/shape10.png" alt="shape">
        </div>
        <div class="shape12">
            <img src="../../assets/images/shape/shape11.png" alt="shape">
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeSeoQuotes'
}
</script>