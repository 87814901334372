<template>
    <div class="services-area pb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR SERVICES</span>
                <h2>We Offer Professional Services For Business</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="services-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-services-item">
                            <div class="d-flex align-items-center">
                                <img :src="slide.image" alt="image">
                                <h3><a href="#">{{slide.title}}</a></h3>
                            </div>
                            <p>{{slide.description}}</p>
                            <ul class="features-list">
                                <li
                                    v-for="features in slide.featuresList"
                                    :key="features.id"
                                >
                                    <span>
                                        <i :class="features.icon"></i> 
                                        {{features.feature}}
                                    </span>
                                </li>
                            </ul>
                            <router-link :to="slide.btnLink" class="default-btn">
                                <span class="ri-arrow-right-s-line"></span>
                                {{slide.btnText}}
                            </router-link>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'WeOfferProfessional',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'left',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/icon/icon8.png'),
                title: 'Branding',
                description: 'Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.',
                featuresList: [
                    {
                        id: 1,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Identity'
                    },
                    {
                        id: 2,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Voice'
                    },
                    {
                        id: 3,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Positioning'
                    },
                    {
                        id: 4,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'competitor Farm'
                    },
                ],
                btnText: 'Learn More',
                btnLink: '/services',
            },
            {
                id: 2,
                image: require('../../assets/images/icon/icon9.png'),
                title: 'Design',
                description: 'Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.',
                featuresList: [
                    {
                        id: 1,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'App Design'
                    },
                    {
                        id: 2,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Website Design'
                    },
                    {
                        id: 3,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Product Design'
                    },
                    {
                        id: 4,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'UX Design'
                    },
                ],
                btnText: 'Learn More',
                btnLink: '/services',
            },
            {
                id: 3,
                image: require('../../assets/images/icon/icon10.png'),
                title: 'Content',
                description: 'Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.',
                featuresList: [
                    {
                        id: 1,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Photography'
                    },
                    {
                        id: 2,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Animations'
                    },
                    {
                        id: 3,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Copywriting'
                    },
                    {
                        id: 4,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Social Content'
                    },
                ],
                btnText: 'Learn More',
                btnLink: '/services',
            },
            {
                id: 4,
                image: require('../../assets/images/icon/icon11.png'),
                title: 'Strategy',
                description: 'Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.',
                featuresList: [
                    {
                        id: 1,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Research'
                    },
                    {
                        id: 2,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Positioning'
                    },
                    {
                        id: 3,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Analysis'
                    },
                    {
                        id: 4,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Market Strategy'
                    },
                ],
                btnText: 'Learn More',
                btnLink: '/services',
            },
            {
                id: 5,
                image: require('../../assets/images/icon/icon12.png'),
                title: 'Marketing',
                description: 'Lorem ipsum dolor sit amet, consectetur adiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.',
                featuresList: [
                    {
                        id: 1,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Email Campaigns'
                    },
                    {
                        id: 2,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Social Campaigns'
                    },
                    {
                        id: 3,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Positioning'
                    },
                    {
                        id: 4,
                        icon: 'ri-checkbox-circle-fill',
                        feature: 'Print Campaigns'
                    },
                ],
                btnText: 'Learn More',
                btnLink: '/services',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },                
        },
    }),
})
</script>