<template>
    <div>
        <Header />
        <ElanEducation />
        <RelatedCaseStudies />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import ElanEducation from '../WorkDetails/ElanEducation'
import RelatedCaseStudies from '../WorkDetails/RelatedCaseStudies'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        ElanEducation,
        RelatedCaseStudies,
        LetsTalkSomething
    }
}
</script>