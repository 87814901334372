<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="river-responsive-nav">
            <div class="container">
                <div class="river-responsive-menu">
                    <div class="logo">
                        <router-link to="/">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </router-link>
                    </div>

                    <div 
                        class="navbar-toggler" 
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                    </div>
                
                    <div class="others-option others-option-two">
                        <router-link to="/contact" class="default-btn style-two">
                            <span class="ri-edit-2-line"></span>
                            Get A Free Quote
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-light" :class="{ toggler: active }">
            <div class="container">
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="#" class="dropdown-toggle nav-link">Home</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link" exact>Home Demo - 1</router-link>
                                    <router-link to="/home-two" class="nav-link">Home Demo - 2</router-link>
                                    <router-link to="/home-three" class="nav-link">Home Demo - 3</router-link>
                                    <router-link to="/home-four" class="nav-link">Cybersecurity</router-link>
                                    <router-link to="/home-five" class="nav-link">Cryptocurrency</router-link>
                                    <router-link to="/home-six" class="nav-link">ICO Landing</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link to="/about-us" class="nav-link">About Us</router-link>
                        </li>
                        <li class="nav-item megamenu"><a href="#" class="dropdown-toggle nav-link">Services</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/services" class="nav-link">
                                        <img src="../../assets/images/icon/icon17.png" alt="image">
                                        Content Writing
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services-one" class="nav-link">
                                        <img src="../../assets/images/icon/icon18.png" alt="image">
                                        Social Marketing
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services" class="nav-link">
                                        <img src="../../assets/images/icon/icon19.png" alt="image">
                                        Email Marketing
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services-one" class="nav-link">
                                        <img src="../../assets/images/icon/icon20.png" alt="image">
                                        Lead Guideline
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services" class="nav-link">
                                        <img src="../../assets/images/icon/icon21.png" alt="image">
                                        Content Marketing
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/services-one" class="nav-link">
                                        <img src="../../assets/images/icon/icon22.png" alt="image">
                                        Video Marketing
                                        <p>Learn the basics with expert resources.</p>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="#" class="dropdown-toggle nav-link">Case Studies</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><router-link to="/work-style-one" class="nav-link">Work Style #1</router-link></li>
                                <li class="nav-item"><router-link to="/work-style-two" class="nav-link">Work Style #2 – Default</router-link></li>
                                <li class="nav-item"><router-link to="/work-style-three" class="nav-link">Work Style #3 - Extra</router-link></li>
                                <li class="nav-item"><router-link to="/work-details" class="nav-link">Work Details</router-link></li>
                            </ul>
                        </li>
                        <li class="nav-item"><router-link to="/team" class="nav-link">Team</router-link></li>
                        <li class="nav-item"><router-link to="/pricing" class="nav-link">Pricing</router-link></li>
                        <li class="nav-item"><router-link to="/blog" class="nav-link">Blog</router-link></li>
                        <li class="nav-item"><router-link to="/contact" class="nav-link">Contact</router-link></li>
                    </ul>
                    <div class="others-option">
                        <router-link to="/contact" class="default-btn style-two"><span class="ri-edit-2-line"></span>Get A Free Quote</router-link>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import 'remixicon/fonts/remixicon.css'

export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    }
}
</script>