<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content text-center">
                <h1>Case Studies</h1>
                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li>Case Studies</li>
                </ul>
                <div class="clients-list">
                    <span>EXPLORE OUR LATEST CLIENTS PROJECTS</span>
                    <div class="row justify-content-center">
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo1.png" alt="image"></router-link>
                            </div>
                        </div>
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo2.png" alt="image"></router-link>
                            </div>
                        </div>
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo3.png" alt="image"></router-link>
                            </div>
                        </div>
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo4.png" alt="image"></router-link>
                            </div>
                        </div>
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo5.png" alt="image"></router-link>
                            </div>
                        </div>
                        <div class="col-lg-2 col-4 col-sm-3 col-md-2">
                            <div class="box">
                                <router-link to="/work-details" target="_blank"><img src="../../assets/images/partner/logo6.png" alt="image"></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'PageTitleTwo'
}
</script>