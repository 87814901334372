<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">AWARD WINNING COMPANY</span>
                        <h1>Let's Make Wave Amazing Together</h1>
                        <router-link to="/contact" class="default-btn style-two"><span class="ri-arrow-right-s-line"></span>Get Started Now</router-link>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img src="../../assets/images/woman.png" alt="image">
                        <div class="shape"><img src="../../assets/images/shape/shape8.png" alt="image"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape2"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape3.png" alt="image"></div>
        <div class="shape4"><img src="../../assets/images/shape/shape4.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>