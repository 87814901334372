<template>
    <div class="bg-gradient-color">
        <ATrustedPartner />
        <WeOfferProfessional />
        <Process />
    </div>
</template>

<script>
import ATrustedPartner from '../Common/ATrustedPartner'
import WeOfferProfessional from '../HomeOne/WeOfferProfessional'
import Process from '../Common/Process'

export default {
    name: 'BgGradient',

    components: {
        ATrustedPartner,
        WeOfferProfessional,
        Process
    }
}
</script>