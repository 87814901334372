<template>
    <footer class="footer-style-area pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img src="../../assets/images/white-logo.png" alt="logo">
                        </router-link>
                        <p>Donec sollicitudin molestie malesuada vivamus magna justo lacinia eget consectetur sed convallis at tellus donec rutrum congue leo eget malesuada.</p>

                        <div class="info">
                            <i class="ri-time-line"></i>
                            <h4>Opening Hours:</h4>
                            <span>7:00AM - 10:00PM</span>
                            <span>Open 7 Days a Week</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Solution</h3>
                        <ul class="services-links">
                            <li><router-link to="/services.html">Cyber Security</router-link></li>
                            <li><router-link to="/services-2.html">Enterprise Networks</router-link></li>
                            <li><router-link to="/services.html">Collaboration</router-link></li>
                            <li><router-link to="/services-2.html">Website Security</router-link></li>
                            <li><router-link to="/services.html">Malware Attack</router-link></li>
                            <li><router-link to="/services.html">Phishing Attack</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="quick-links">
                            <li><router-link to="/index.html">Home</router-link></li>
                            <li><router-link to="/about-us.html">About Us</router-link></li>
                            <li><router-link to="/work-style-1.html">Case Studies</router-link></li>
                            <li><router-link to="/blog.html">Our News</router-link></li>
                            <li><router-link to="/contact.html">Contact Us</router-link></li>
                            <li><router-link to="/pricing.html">Pricing</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li><i class="ri-map-pin-line"></i> Address: 2750, Quadra Street Victoria, Canada</li>
                            <li><i class="ri-mail-line"></i> Email: <a href="mailto:hello@river.com">hello@river.com</a></li>
                            <li><i class="ri-phone-line"></i> Phone: <a href="tel:+44587154756">+44 587 154756</a></li>
                            <li><i class="ri-headphone-line"></i> Fax: <a href="tel:+44587154756">+55 58715 4756</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <p>Copyright {{currentYear}} <strong>River</strong>. All Rights Reserved by <a href="https://7midia.com/" target="_blank">7midia</a></p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterTwo',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>