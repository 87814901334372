<template>
    <div class="subscribe-area pb-100">
        <div class="container">
            <div class="subscribe-inner">
                <span class="sub-title">READY TO GET STARTED</span>
                <h2>Subscribe To Our Newsletter</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL">
                    <button type="submit">
                        Subscribe Now 
                        <i class="ri-arrow-right-s-line"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscribeToOurNewsletter'
}
</script>