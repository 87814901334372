<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Flexible Pricing" pageTitle="Pricing" />
        <PricingContent />
        <FrequentlyAskedQuestions class="bg-gradient-image" />
        <PlansForEverything />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import PricingContent from '../Pricing/PricingContent'
import FrequentlyAskedQuestions from '../Common/FrequentlyAskedQuestions'
import PlansForEverything from '../Pricing/PlansForEverything'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitle,
        PricingContent,
        FrequentlyAskedQuestions,
        PlansForEverything,
        LetsTalkSomething,
    }
}
</script>