<template>
    <div class="cyber-safety-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cyber-safety-image">
                        <img src="../../assets/images/cybersecurity-home/cyber-safety.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cyber-safety-content">
                        <span class="sub-title">Cyber Safety</span>
                        <h2>Protect Your Self Against Cyberattacks</h2>
                        <p>Donec sollicitudin molestie malesuada vivamus magna justo lacinia eget consectetur sed convallis at tellus donec rutrum congue leo eget malesuada quisque velit nisi pretium ut lacinia in elementum.</p>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <ul class="safety-list">
                                    <li><i class="ri-checkbox-circle-line"></i> Update your software</li>
                                    <li><i class="ri-checkbox-circle-line"></i> Don't open unknown sender email</li>
                                    <li><i class="ri-checkbox-circle-line"></i> Used strong password</li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <ul class="safety-list">
                                    <li><i class="ri-checkbox-circle-line"></i> Cloud security</li>
                                    <li><i class="ri-checkbox-circle-line"></i> Coding languages</li>
                                    <li><i class="ri-checkbox-circle-line"></i> Network security control</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CyberSafety'
}
</script>