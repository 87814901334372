<template>
    <div class="team-area bg-gradient-image ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-member-list">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-team-member">
                                    <img src="../../assets/images/team/team12.jpg" alt="team">
                                    <div class="content">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h3>Ben Stokes</h3>
                                            <span>Marketing Lead</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-team-member">
                                    <img src="../../assets/images/team/team13.jpg" alt="team">
                                    <div class="content">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h3>Sarah Taylor</h3>
                                            <span>Art Director</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-team-member">
                                    <img src="../../assets/images/team/team14.jpg" alt="team">
                                    <div class="content">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h3>Ellyse Perry</h3>
                                            <span>Designer</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-team-member">
                                    <img src="../../assets/images/team/team15.jpg" alt="team">
                                    <div class="content">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h3>Steven Smith</h3>
                                            <span>Development</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="team-section-title">
                        <span class="sub-title">OUR EXPERTS</span>
                        <h2>Meet Our Leadership <br>Preparing For Your Success</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                        <router-link to="/team" class="default-btn style-two">
                            <span class="ri-arrow-right-s-line"></span>
                            Meet Our Team
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape10">
            <img src="../../assets/images/shape/shape9.png" alt="shape">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MeetOurLeadership'
}
</script>