<template>
    <div class="roadmap-area pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Roadmap</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="roadmap-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="roadmap-style-card with-black-color">
                            <span class="sub-title">{{slide.subTitle}}</span>

                            <div class="content">
                                <h3>{{slide.title}}</h3>
                                <p>{{slide.description}}</p>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Roadmap',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                subTitle: 'Q1 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q2 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q33 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q4 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q5 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q6 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
            {
                id: 1,
                subTitle: 'Q7 2022',
                title: 'Exchange Listing',
                description: 'Pellentesque in ipsum id orci porta dapibus mauris blandit aliquet elit eget tincidunt nibh pulvinar sed porttitor lectus nibh.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>