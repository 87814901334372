<template>
    <div class="blog-style-area pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Latest News</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="blog-style-card">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/cybersecurity-home/blog/blog-1.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="meta">
                                <li>By <router-link to="/blog">Admin</router-link></li>
                                <li>March 15, 2022</li>
                            </ul>
                            <h3>
                                <router-link to="/blog-details">
                                    Proper Guide Line Learning Strategies About Cyber Security
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="blog-style-card">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/cybersecurity-home/blog/blog-2.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="meta">
                                <li>By <router-link to="/blog">Admin</router-link></li>
                                <li>March 15, 2022</li>
                            </ul>
                            <h3>
                                <router-link to="/blog-details">
                                    Rules To Protect Your Account From Cyber Attack
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="blog-style-card">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img src="../../assets/images/cybersecurity-home/blog/blog-3.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="meta">
                                <li>By <router-link to="/blog">Admin</router-link></li>
                                <li>March 15, 2022</li>
                            </ul>
                            <h3>
                                <router-link to="/blog-details">
                                    Rules For Introducing Web Code To Increase Cyber Security
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-style-shape">
            <img src="../../assets/images/cybersecurity-home/blog/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>