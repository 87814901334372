<template>
    <div class="what-we-work-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="what-we-work-image">
                        <img src="../../assets/images/cybersecurity-home/what-we-work/what-we-work.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="what-we-work-content">
                        <span class="sub-title">What We Work</span>
                        <h2>All The Procedures That We Operate For Security</h2>

                        <div class="we-work-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cybersecurity-home/what-we-work/image-1.png" alt="image">
                            </div>
                            <h3>Trusted Partner</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="we-work-inner-box with-some-left">
                            <div class="image">
                                <img src="../../assets/images/cybersecurity-home/what-we-work/image-2.png" alt="image">
                            </div>
                            <h3>System Security</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="we-work-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cybersecurity-home/what-we-work/image-3.png" alt="image">
                            </div>
                            <h3>Product Security</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="what-we-work-shape">
            <img src="../../assets/images/cybersecurity-home/what-we-work/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeWork'
}
</script>