<template>
    <div class="contact-us-area">
        <div class="container">
            <div class="contact-us-inner-box with-black-color">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="contact-form-wrap">
                            <h2>Contact Us</h2>

                            <form id="contactForm">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter your name">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                </div>
                                <div class="form-group">
                                    <textarea name="message" class="form-control" placeholder="Enter message..."></textarea>
                                </div>
                                <div class="contact-us-btn">
                                    <button type="submit" class="default-btn style-two"><span class="ri-arrow-right-s-line"></span>Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="get-in-touch-information">
                            <h4>Get in Touch</h4>

                            <ul class="information-list">
                                <li>
                                    <i class="ri-map-pin-line"></i> 
                                    Address: 2750, Quadra Street Victoria, Canada
                                </li>
                                <li>
                                    <i class="ri-phone-line"></i> 
                                    <a href="tel:+44587154756">+44 587 154756</a>
                                    <a href="tel:+55587154756">+55 58715 4756</a>
                                </li>
                                <li>
                                    <i class="ri-mail-line"></i> 
                                    <a href="mailto:hello@river.com">hello@river.com</a>
                                    <a href="mailto:river@info.com">river@info.com</a>
                                </li>
                                <li>
                                    <i class="ri-time-line"></i>
                                    <span>Opening Hours:</span> 7:00am - 10:00pm Open 7 Days a Week
                                </li>
                            </ul>
                            <div class="get-in-touch-shape">
                                <img src="../../assets/images/cryptocurrency-home/get-in-touch-shape.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>