<template>
    <div class="download-app-area">
        <div class="container">
            <div class="download-app-inner-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="download-app-content">
                            <span class="sub-title">Download App</span>
                            <h3>Download Your Free Copy Today From Apple And Play Store</h3>

                            <ul class="app-list">
                                <li>
                                    <a href="#"><img src="../../assets/images/cryptocurrency-home/download-app/app-1.png" alt="image"></a>
                                </li>
                                <li>
                                    <a href="#"><img src="../../assets/images/cryptocurrency-home/download-app/app-2.png" alt="image"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="download-app-image">
                            <img src="../../assets/images/cryptocurrency-home/download-app/download-app.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DownloadApp'
}
</script>