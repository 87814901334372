<template>
    <div class="process-area">
        <div class="container">
            <div class="process-inner bg-color">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon14.png" alt="image">
                            </div>
                            <h3>Select Your Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon15.png" alt="image">
                            </div>
                            <h3>Work Smart Not Hard</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon16.png" alt="image">
                            </div>
                            <h3>We've Got Your Back</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Process'
}
</script>