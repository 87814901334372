<template>
    <div class="ico-faq-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ico-faq-image">
                        <img src="../../assets/images/ico-landing-home/faq.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ico-faq-content">
                        <h2>Frequently Asked Questions</h2>
                        <p>Sed porttitor lectus nibh. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
                    </div>
                    <div class="ico-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is River ICO?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem. Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        How much does it cost to use River?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem. Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Who can invest through River?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem. Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        How can I open a River account?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem. Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What technology are we using?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem. Nulla quis lorem ut libero malesuada feugiat pellentesque in ipsum id orci porta dapibus.</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'FAQ',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>