<template>
    <div class="pre-token-sale-ends-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="pre-token-sale-ends-content">
                        <h3>Pre Token Sale Ends</h3>
                        <div id="timer" class="flex-wrap d-flex justify-content-center">
                            <div 
                                id="days" 
                                class="align-items-center flex-column d-flex justify-content-center"
                            >
                                {{days}} 
                                <span>Days</span>
                            </div>
                            <div 
                                id="hours" 
                                class="align-items-center flex-column d-flex justify-content-center"
                            >
                                {{hours}} 
                                <span>Hours</span>
                            </div>
                            <div 
                                id="minutes" 
                                class="align-items-center flex-column d-flex justify-content-center"
                            >
                                {{minutes}} 
                                <span>Minutes</span>
                            </div>
                            <div 
                                id="seconds" 
                                class="align-items-center flex-column d-flex justify-content-center"
                            >
                                {{seconds}} 
                                <span>Seconds</span>
                            </div>
                        </div>

                        <p>Pre sale total amount 1 ETH = $2,537.37</p>
                        <div class="pre-token-sale-ends-with-progress">
                            <ul class="top-list d-flex justify-content-between align-items-center">
                                <li>$5m</li>
                                <li>$50m</li>
                            </ul>
                            <div class="progress-wrapper">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <ul class="bottom-list d-flex justify-content-between align-items-center">
                                <li>Softcap</li>
                                <li>Hardcap</li>
                            </ul>
                        </div>
                        <ul class="pre-token-sale-ends-btn">
                            <li>
                                <a href="#" class="buy-token-btn">Buy Tokens</a>
                            </li>
                            <li>Minimum Purchase: 10 ICO Token</li>
                        </ul>

                        <div class="pre-token-sale-ends-shape">
                            <img src="../../assets/images/cryptocurrency-home/pre-token-sale-ends/shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="pre-token-sale-ends-image">
                        <img src="../../assets/images/cryptocurrency-home/pre-token-sale-ends/pre-token-sale-ends.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreToken',
    data() {
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: ''
        }
    },
    created() { // Turn data into viewable values
        setInterval(() => {
            this.commingSoonTime();
        }, 1000);
    },
    methods: {
        commingSoonTime() {
            let endTime = new Date("August 23, 2026 17:00:00 PDT");			
            let endTimeParse = (Date.parse(endTime)) / 1000;
            let now = new Date();
            let nowParse = (Date.parse(now) / 1000);
            let timeLeft = endTimeParse - nowParse;
            let days = Math.floor(timeLeft / 86400); 
            let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
            let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        }
    }
}
</script>