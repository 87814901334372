<template>
    <div class="services-details-area pb-100">
        <div class="container">
            <div class="services-details-content">
                <div class="box">
                    <img src="../../assets/images/partner/logo1.png" alt="image">
                </div>
                <span class="sub-title">Social Marketing</span>
                <h1>Let’s Talk Something Amazing Together!</h1>
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label>Name *</label>
                                    <input type="text" name="name" class="form-control" placeholder="Eg: Sarah Taylor">
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email *</label>
                                    <input type="email" name="email" class="form-control" placeholder="hello@sarah.com">
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>Phone *</label>
                                    <input type="text" name="phone-number" class="form-control" placeholder="Enter your phone number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" class="default-btn"><span class="ri-arrow-right-s-line"></span>Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="../../assets/images/shape/shape1.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'LetsTalkSomethingAmazing'
}
</script>