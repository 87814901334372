<template>
    <div class="about-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/about2.png" alt="image">
                        <div class="shape5">
                            <img src="../../assets/images/shape/shape5.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">ABOUT US</span>
                        <h2>We Are Award Winning Digital Marketing Company</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul class="features-list">
                            <li>
                                <div class="d-flex align-items-center">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/icon4.png" alt="image">
                                    </div>
                                    <span>Brand Strategy</span>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex align-items-center">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/icon5.png" alt="image">
                                    </div>
                                    <span>Expert Team</span>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex align-items-center">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/icon6.png" alt="image">
                                    </div>
                                    <span>Fast Delivery</span>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex align-items-center">
                                    <div class="icon">
                                        <img src="../../assets/images/icon/icon7.png" alt="image">
                                    </div>
                                    <span>Ongoing Report</span>
                                </div>
                            </li>
                        </ul>
                        <router-link to="/about-us" class="default-btn">
                            <span class="ri-arrow-right-s-line"></span>
                            More About Us
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/images/shape/shape6.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'WeAreAwardWinning'
}
</script>