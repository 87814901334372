<template>
    <div>
        <Header />
        <MainBanner />
        <BeEverywhereYour />
        <WeAreAwardWinning />
        <BgGradient />
        <WeGetYouHigherRevenues />
        <MeetOurLeadership />
        <OurLatestInsights />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import MainBanner from '../HomeOne/MainBanner'
import BeEverywhereYour from '../HomeOne/BeEverywhereYour'
import WeAreAwardWinning from '../HomeOne/WeAreAwardWinning'
import BgGradient from '../HomeOne/BgGradient'
import WeGetYouHigherRevenues from '../Common/WeGetYouHigherRevenues'
import MeetOurLeadership from '../HomeOne/MeetOurLeadership'
import OurLatestInsights from '../Common/OurLatestInsights'
import LetsTalkSomething from '../Common/LetsTalkSomething'
export default {
    name: 'HomePageOne',
    components: {
        Header,
        MainBanner,
        BeEverywhereYour,
        WeAreAwardWinning,
        BgGradient,
        WeGetYouHigherRevenues,
        MeetOurLeadership,
        OurLatestInsights,
        LetsTalkSomething,
    }
}
</script>