<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="About Us" pageTitle="About Us" />
        <WeAreAwardWinningTwo />
        <ATrustedPartner/>
        <Process class="ptb-100" />
        <WhatOurClients />
        <MeetOurLeadershipTwo />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import WeAreAwardWinningTwo from '../About/WeAreAwardWinningTwo'
import ATrustedPartner from '../Common/ATrustedPartner'
import Process from '../Common/Process'
import WhatOurClients from '../Common/WhatOurClients'
import MeetOurLeadershipTwo from '../About/MeetOurLeadershipTwo'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitle,
        WeAreAwardWinningTwo,
        ATrustedPartner,
        Process,
        WhatOurClients,
        MeetOurLeadershipTwo,
        LetsTalkSomething
    }
}
</script>