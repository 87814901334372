<template>
    <div class="team-area bg-gradient-image ptb-100">
        <div class="container">
            <div class="section-title style-two">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <span class="sub-title">OUR EXPERTS</span>
                        <h2>Meet Our Leadership <br>Preparing For Your Success</h2>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters. It is a long established fact that a reader will be distracted by the.</p>
                    </div>
                </div>
            </div>

            <div class="team-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-team-box">
                            <div class="image">
                                <div :class="slide.class"></div>
                            </div>
                            <div class="content">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MeetOurLeadership',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'bg-1',
                name: 'Sarah Taylor',
                position: 'Marketing Lead',
            },
            {
                id: 2,
                class: 'bg-2',
                name: 'Ben Stokes',
                position: 'Art Director',
            },
            {
                id: 3,
                class: 'bg-3',
                name: 'Ellyse Perry',
                position: 'Designer',
            },
            {
                id: 4,
                class: 'bg-4',
                name: 'Steven Smith',
                position: 'Development',
            },
            {
                id: 5,
                class: 'bg-5',
                name: 'Suzie Bates',
                position: 'Front-End Development',
            },
            {
                id: 6,
                class: 'bg-6',
                name: 'David Warner',
                position: 'Head of Team',
            },
            {
                id: 7,
                class: 'bg-2',
                name: 'Ben Stokes',
                position: 'Art Director',
            },
            {
                id: 8,
                class: 'bg-3',
                name: 'Ellyse Perry',
                position: 'Designer',
            },
            {
                id: 9,
                class: 'bg-4',
                name: 'Steven Smith',
                position: 'Development',
            },
            {
                id: 10,
                class: 'bg-5',
                name: 'Suzie Bates',
                position: 'Front-End Development',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
    }),
})
</script>