<template>
    <div class="case-studies-details-area ptb-100">
        <div class="container">
            <div class="case-studies-details-header row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../assets/images/case-studies/case-studies10.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <img src="../../assets/images/case-studies/elan.png" alt="image">
                        <h1>eLan Education</h1>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <ul class="meta">
                            <li>
                                <i class="ri-folder-2-line"></i>
                                <span>APP</span>
                                <a href="https://wordpress.com/" target="_blank">WordPress</a>
                            </li>
                            <li>
                                <i class="ri-price-tag-3-line"></i>
                                <span>CATEGORY</span>
                                <router-link to="/work-style-one">Development</router-link>
                            </li>
                            <li>
                                <i class="ri-global-line"></i>
                                <span>LINK</span>
                                <a href="https://wordpress.com/start/user?ref=logged-out-homepage-lp" target="_blank">www.example.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="case-studies-details-desc">
                <div class="article-content">
                    <div class="row mb-40">
                        <div class="col-lg-5 col-md-12">
                            <h2><span>THE CHALLENGE</span></h2>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>So, What Exactly is Positioning and Why Does It Matter?</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single <a href="#">gamepad</a> for play on the</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the <strong>television</strong> for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-40">
                        <div class="col-lg-5 col-md-12">
                            <h2><span>THE SOLUTION</span></h2>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>How We Defined Our Positioning: a Step-by-Step Overview</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                <blockquote class="wp-block-quote">
                                    <p>It is a long established fact that a reader will be distracted by the readable when content of a page when looking at its layout.</p>
                                    <cite>Tom Cruise</cite>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-40">
                        <div class="col-lg-5 col-md-12">
                            <h2><span>THE RESULT</span></h2>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="content">
                                <h3>When Content Of a Page When Looking at Its Layout</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                <ul class="wp-block-gallery columns-3">
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../assets/images/services/services1.jpg" alt="image">
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../assets/images/services/services2.jpg" alt="image">
                                        </figure>
                                    </li>
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../assets/images/services/services3.jpg" alt="image">
                                        </figure>
                                    </li>
                                </ul>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            </div>
                        </div>
                    </div>
                    <div class="article-tags">
                        <router-link to="/work-style-one">#Business</router-link>
                        <router-link to="/work-style-one">#Startup</router-link>
                        <router-link to="/work-style-one">#Agency</router-link>
                        <router-link to="/work-style-one">#Marketing</router-link>
                        <router-link to="/work-style-one">#Social</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ElanEducation'
}
</script>