<template>
    <div class="process-area bg-gradient-image ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR PROCESS</span>
                <h2>How We Works?</h2>
            </div>
            <div class="process-inner">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon14.png" alt="image">
                            </div>
                            <h3>Select Your Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon15.png" alt="image">
                            </div>
                            <h3>Optimizations</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-process-box">
                            <div class="icon">
                                <img src="../../assets/images/icon/icon16.png" alt="image">
                            </div>
                            <h3>We've Got Your Back</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowWeWorks'
}
</script>