<template>
    <div>
        <NavbarTwo />
        <MainBanner />
        <Partner />
        <WeOfferProfessional />
        <WeAreAwardWinning />
        <OurValuableClients />
        <Process />
        <WeGetYouHigherRevenues />
        <MeetOurLeadership />
        <OurLatestInsights />
        <LetsTalkSomething />
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwo'
import MainBanner from '../HomeTwo/MainBanner'
import Partner from '../HomeTwo/Partner'
import WeOfferProfessional from '../Common/WeOfferProfessional'
import WeAreAwardWinning from '../HomeTwo/WeAreAwardWinning'
import OurValuableClients from '../HomeTwo/OurValuableClients'
import Process from '../HomeTwo/Process'
import WeGetYouHigherRevenues from '../Common/WeGetYouHigherRevenues'
import MeetOurLeadership from '../Common/MeetOurLeadership'
import OurLatestInsights from '../Common/OurLatestInsights'
import LetsTalkSomething from '../HomeTwo/LetsTalkSomething'

export default {
    components: {
        NavbarTwo,
        MainBanner,
        Partner,
        WeOfferProfessional,
        WeAreAwardWinning,
        OurValuableClients,
        Process,
        WeGetYouHigherRevenues,
        MeetOurLeadership,
        OurLatestInsights,
        LetsTalkSomething,
    }
}
</script>