<template>
    <div class="pricing-style-area pt-100 pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Our Pricing Plan</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-card">
                        <div class="pricing-header">
                            <h3>Basic</h3>
                        </div>
                        <div class="price">
                            <h4>Free<span>/Per Month</span></h4>
                        </div>
                        <div class="features-list">
                            <ul>
                                <li><i class="ri-checkbox-circle-line"></i> 30 Days product testing</li>
                                <li><i class="ri-checkbox-circle-line"></i> Upgrade anytime protection</li>
                                <li><i class="ri-checkbox-circle-line"></i> Unlimited malware removal</li>
                                <li><i class="ri-checkbox-circle-line"></i> 24/7 Live assistance</li>
                                <li><i class="ri-checkbox-circle-line"></i> Scan every 13 Hrs</li>
                                <li><i class="ri-checkbox-circle-line"></i> Configure software</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="pricing-shape">
                            <img src="../../assets/images/cybersecurity-home/pricing-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-card">
                        <div class="pricing-header">
                            <h3>Starter</h3>
                        </div>
                        <div class="price">
                            <h4>$49<span>/Per Month</span></h4>
                        </div>
                        <div class="features-list">
                            <ul>
                                <li><i class="ri-checkbox-circle-line"></i> 30 Days product testing</li>
                                <li><i class="ri-checkbox-circle-line"></i> Upgrade anytime protection</li>
                                <li><i class="ri-checkbox-circle-line"></i> Unlimited malware removal</li>
                                <li><i class="ri-checkbox-circle-line"></i> 24/7 Live assistance</li>
                                <li><i class="ri-checkbox-circle-line"></i> Scan every 13 Hrs</li>
                                <li><i class="ri-checkbox-circle-line"></i> Configure software</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="pricing-shape">
                            <img src="../../assets/images/cybersecurity-home/pricing-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-card">
                        <div class="pricing-header">
                            <h3>Exclusive</h3>
                        </div>
                        <div class="price">
                            <h4>$99<span>/Per Month</span></h4>
                        </div>
                        <div class="features-list">
                            <ul>
                                <li><i class="ri-checkbox-circle-line"></i> 30 Days product testing</li>
                                <li><i class="ri-checkbox-circle-line"></i> Upgrade anytime protection</li>
                                <li><i class="ri-checkbox-circle-line"></i> Unlimited malware removal</li>
                                <li><i class="ri-checkbox-circle-line"></i> 24/7 Live assistance</li>
                                <li><i class="ri-checkbox-circle-line"></i> Scan every 13 Hrs</li>
                                <li><i class="ri-checkbox-circle-line"></i> Configure software</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="pricing-shape">
                            <img src="../../assets/images/cybersecurity-home/pricing-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>