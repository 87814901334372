<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Privacy Policy" pageTitle="Privacy Policy" />
        <PrivacyPolicyContent />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        Header,
        PageTitle,
        PrivacyPolicyContent
    }
}
</script>