<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </router-link>
                        <div class="newsletter-form">
                            <p>SUBSCRIBE TO OUR NEWSLETTER</p>
                            <form data-toggle="validator">
                                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                                <button type="submit">Subscribe Now <i class="ri-send-plane-line"></i></button>
                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                        <ul class="social-links">
                            <li><a href="https://facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                            <li><a href="https://linkedin.com/" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                            <li><a href="https://instagram.com/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Services</h3>
                        <ul class="services-links">
                            <li><router-link to="/services">Marketing</router-link></li>
                            <li><router-link to="/services-one">Web Development</router-link></li>
                            <li><router-link to="/services">App Design</router-link></li>
                            <li><router-link to="/services-one">Product Branding</router-link></li>
                            <li><router-link to="/services">Content Writing</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Quick Links</h3>
                        <ul class="quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/work-style-one">Case Studies</router-link></li>
                            <li><router-link to="/blog">Our News</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li>Address: 2750, Quadra Street Victoria, Canada</li>
                            <li>Email: <a href="mailto:hello@river.com">hello@river.com</a></li>
                            <li>Phone: <a href="tel:+44587154756">+44 587 154756</a></li>
                            <li>Fax: <a href="tel:+44587154756">+55 58715 4756</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="copyright-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <p>Copyright {{currentYear}} <strong>River</strong>. All Rights Reserved by <a href="https://7midia.com/" target="_blank">7midia</a></p>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <ul>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>