<template>
    <div>
        <NavbarTwo />
        <MainBanner />
        <WeOfferProfessional class="ptb-100" />
        <WeAreAwardWinning />
        <Funfacts />
        <FreeSeoQuotes />
        <OurValuableClients />
        <WeGetYouHigherRevenues class="pt-0" />
        <MeetOurLeadership />
        <OurLatestInsights />
        <SubscribeToOurNewsletter />
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwo'
import MainBanner from '../HomeThree/MainBanner'
import WeOfferProfessional from '../Common/WeOfferProfessional'
import WeAreAwardWinning from '../HomeThree/WeAreAwardWinning'
import Funfacts from '../HomeThree/Funfacts'
import FreeSeoQuotes from '../HomeThree/FreeSeoQuotes'
import OurValuableClients from '../HomeThree/OurValuableClients'
import WeGetYouHigherRevenues from '../Common/WeGetYouHigherRevenues'
import MeetOurLeadership from '../Common/MeetOurLeadership'
import OurLatestInsights from '../Common/OurLatestInsights'
import SubscribeToOurNewsletter from '../HomeThree/SubscribeToOurNewsletter'

export default {
    components: {
        NavbarTwo,
        MainBanner,
        WeOfferProfessional,
        WeAreAwardWinning,
        Funfacts,
        FreeSeoQuotes,
        OurValuableClients,
        WeGetYouHigherRevenues,
        MeetOurLeadership,
        OurLatestInsights,
        SubscribeToOurNewsletter,
    }
}
</script>