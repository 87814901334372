<template>
    <div class="invest-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="invest-content">
                        <h2>Reasons To Invest In Cryptocurrency</h2>

                        <div class="invest-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/invest/image-1.png" alt="image">
                            </div>
                            <h3>Control Over Your Assets</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="invest-inner-box with-some-left">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/invest/image-2.png" alt="image">
                            </div>
                            <h3>Transparent And Secure</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                        <div class="invest-inner-box">
                            <div class="image">
                                <img src="../../assets/images/cryptocurrency-home/invest/image-3.png" alt="image">
                            </div>
                            <h3>Good Choice For Long Term Investment</h3>
                            <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="invest-image">
                        <img src="../../assets/images/cryptocurrency-home/invest/invest.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Invest'
}
</script>