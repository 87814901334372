<template>
    <div>
        <ValueTrade />
        <NavbarThree class="with-white-color" />
        <MainBanner />
        <div class="background-with-gradient-color">
            <CryptocurrencyAbout />
            <Features />
            <PreToken />
            <Platforms />
            <CryptocurrencyChoose />
            <TokenDistribution />
            <Invest />
            <Roadmap />
            <TeamMember />
            <OurLeadership />
            <DownloadApp />
            <IndustryPartner />
            <ContactUs />
            <FooterThree />
        </div>
    </div>
</template>

<script>
import ValueTrade from '../Layout/ValueTrade'
import NavbarThree from '../Layout/NavbarThree'
import MainBanner from '../HomeFive/MainBanner'
import CryptocurrencyAbout from '../HomeFive/CryptocurrencyAbout'
import Features from '../HomeFive/Features'
import PreToken from '../HomeFive/PreToken'
import Platforms from '../HomeFive/Platforms'
import CryptocurrencyChoose from '../HomeFive/CryptocurrencyChoose'
import TokenDistribution from '../HomeFive/TokenDistribution'
import Invest from '../HomeFive/Invest'
import Roadmap from '../HomeFive/Roadmap'
import TeamMember from '../HomeFive/TeamMember'
import OurLeadership from '../HomeFive/OurLeadership'
import DownloadApp from '../HomeFive/DownloadApp'
import IndustryPartner from '../HomeFive/IndustryPartner'
import ContactUs from '../HomeFive/ContactUs'
import FooterThree from '../Layout/FooterThree'

export default {
    name: 'HomePageFive',
    components: {
        ValueTrade,
        NavbarThree,
        MainBanner,
        CryptocurrencyAbout,
        Features,
        PreToken,
        Platforms,
        CryptocurrencyChoose,
        TokenDistribution,
        Invest,
        Roadmap,
        TeamMember,
        OurLeadership,
        DownloadApp,
        IndustryPartner,
        ContactUs,
        FooterThree,
    }
}
</script>