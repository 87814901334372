<template>
    <div class="pricing-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Give River a try for free</p>
                        </div>
                        <div class="price">
                            <h4>$0<span>/month</span></h4>
                            <p>Pricing includes coverage for users</p>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class="ri-check-line"></i> 1 seat</li>
                                <li><i class="ri-check-line"></i> 1 active project</li>
                                <li><i class="ri-check-line"></i> Limited blocks</li>
                                <li><i class="ri-close-line"></i> Conditions</li>
                                <li><i class="ri-close-line"></i> CSV export</li>
                                <li><i class="ri-close-line"></i> Password protection</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Starter</h3>
                            <p>Best for individual designers</p>
                        </div>
                        <div class="price">
                            <h4>$49<span>/month</span></h4>
                            <p>Pricing includes coverage for users</p>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class="ri-check-line"></i> 1 seat</li>
                                <li><i class="ri-check-line"></i> 3 active project</li>
                                <li><i class="ri-check-line"></i> Unlimited blocks</li>
                                <li><i class="ri-check-line"></i> Conditions</li>
                                <li><i class="ri-check-line"></i> CSV export</li>
                                <li><i class="ri-close-line"></i> Password protection</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-box active">
                        <div class="pricing-header">
                            <h3>Team</h3>
                            <p>Get your team together</p>
                        </div>
                        <div class="price">
                            <h4>$39<span>/month</span></h4>
                            <p>Pricing includes coverage for users</p>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class="ri-check-line"></i> 3+ seat</li>
                                <li><i class="ri-check-line"></i> 10 active project</li>
                                <li><i class="ri-check-line"></i> Unlimited blocks</li>
                                <li><i class="ri-check-line"></i> Team management</li>
                                <li><i class="ri-check-line"></i> Customization</li>
                                <li><i class="ri-check-line"></i> Password protection</li>
                            </ul>
                        </div>
                        <div class="ribbon"><span>Popular</span></div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Enterprise</h3>
                            <p>For large teams & enterprises</p>
                        </div>
                        <div class="price">
                            <h4>Custom</h4>
                            <p>Annual billing only</p>
                        </div>
                        <router-link to="/contact" class="link-btn">Contact Us</router-link>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class="ri-check-line"></i> 10+ seat</li>
                                <li><i class="ri-check-line"></i> Unlimited projects</li>
                                <li><i class="ri-check-line"></i> Dedicated CSM</li>
                                <li><i class="ri-check-line"></i> Legal blocks</li>
                                <li><i class="ri-check-line"></i> Dedicated onboarding</li>
                                <li><i class="ri-check-line"></i> Custom payment terms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PricingContent'
}
</script>