<template>
    <div class="value-trade-area">
        <div class="container-fluid">
            <div class="value-trade-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="value-trade-card">
                            <img :src="slide.image" alt="image">
                            <h3><span>{{slide.name}}</span> ${{slide.price}}</h3>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ValueTrade',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-1.png'),
                name: 'Livrecoin',
                price: '0.0001'
            },
            {
                id: 2,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-2.png'),
                name: 'Ethereum',
                price: '2,537.37'
            },
            {
                id: 3,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-3.png'),
                name: 'Tether ',
                price: '1.00'
            },
            {
                id: 4,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-4.png'),
                name: 'BNB',
                price: '375.28'
            },
            {
                id: 5,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-5.png'),
                name: 'XRP',
                price: '0.7435'
            },
            {
                id: 6,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-6.png'),
                name: 'Terra',
                price: '81.47'
            },
            {
                id: 7,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-7.png'),
                name: 'Cardano',
                price: '0.8151'
            },
            {
                id: 8,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-8.png'),
                name: 'Avalanche',
                price: '74.02'
            },
            {
                id: 9,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-9.png'),
                name: 'Dogecoin',
                price: '0.1199'
            },
            {
                id: 10,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-10.png'),
                name: 'USD Coin',
                price: '0.9996'
            },
            {
                id: 11,
                image: require('../../assets/images/cryptocurrency-home/value-trade/trade-11.png'),
                name: 'Cosmos',
                price: '27.82'
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
			},
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 8,
                snapAlign: 'center',
            },
        },
    }),
})
</script>