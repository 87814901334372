<template>
    <div class="services-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">OUR SERVICES</span>
                <h2>We Offer Professional Services For Business</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-1">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon8.png" alt="image">
                            <h3><a href="#">Branding</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> Identity</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Voice</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Positioning</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> competitor Farm</span></li>
                        </ul>
                        <router-link to="/services" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-2">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon9.png" alt="image">
                            <h3><a href="#">Design</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> App Design</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Website Design</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Product Design</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> UX Design</span></li>
                        </ul>
                        <router-link to="/services-one" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-3">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon10.png" alt="image">
                            <h3><a href="#">Content</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> Photography</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Animations</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Copywriting</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Social Content</span></li>
                        </ul>
                        <router-link to="/services" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-4">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon11.png" alt="image">
                            <h3><a href="#">Strategy</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> Research</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Positioning</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Analysis</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Market Strategy</span></li>
                        </ul>
                        <router-link to="/services-one" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-5">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon12.png" alt="image">
                            <h3><a href="#">Marketing</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> Email Campaigns</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Social Campaigns</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Positioning</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Print Campaigns</span></li>
                        </ul>
                        <router-link to="/services" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="single-services-box bg-6">
                        <div class="d-flex align-items-center">
                            <img src="../../assets/images/icon/icon13.png" alt="image">
                            <h3><a href="#">Technology</a></h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <ul class="features-list">
                            <li><span><i class="ri-checkbox-circle-fill"></i> E-Commerce</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Prototyping</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Positioning</span></li>
                            <li><span><i class="ri-checkbox-circle-fill"></i> Platform & APIs</span></li>
                        </ul>
                        <router-link to="/services-one" class="default-btn"><span class="ri-arrow-right-s-line"></span>Learn More</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WeOfferProfessional'
}
</script>