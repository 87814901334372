<template>
    <div class="cyber-security-services-area ptb-100">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Cyber Security Services</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cyber-security-services-card">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="services-image">
                                    <img src="../../assets/images/cybersecurity-home/services/services-1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="services-content">
                                    <h3>
                                        <router-link to="/services">Phishing Attack</router-link>
                                    </h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                                    <router-link to="/services" class="services-btn">Read More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cyber-security-services-card">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="services-image">
                                    <img src="../../assets/images/cybersecurity-home/services/services-2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="services-content">
                                    <h3>
                                        <router-link to="/services">Data Protection</router-link>
                                    </h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                                    <router-link to="/services" class="services-btn">Read More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cyber-security-services-card">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="services-image">
                                    <img src="../../assets/images/cybersecurity-home/services/services-3.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="services-content">
                                    <h3>
                                        <router-link to="/services">Quality Assurance</router-link>
                                    </h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                                    <router-link to="/services" class="services-btn">Read More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cyber-security-services-card">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="services-image">
                                    <img src="../../assets/images/cybersecurity-home/services/services-4.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="services-content">
                                    <h3>
                                        <router-link to="/services">Smart Contraxt</router-link>
                                    </h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                                    <router-link to="/services" class="services-btn">Read More</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="view-all-services-btn">
                        <router-link to="/services" class="default-btn style-two"><span class="ri-arrow-right-s-line"></span>View All Services</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="cyber-security-services-shape">
            <img src="../../assets/images/cybersecurity-home/services/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CyberSecurityServices'
}
</script>