<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Content Writing" pageTitle="Services" />
        <ListsOfContent />
        <LetsTalkSomething />
        <WeGetYouHigherRevenues />
        <HowWeWorks />
        <ATrustedBrands />
        <ContentWritingPricing pricingTitle="Content Writing Pricing" />
        <FrequentlyAskedQuestions />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import ListsOfContent from '../Services/ListsOfContent'
import LetsTalkSomething from '../Common/LetsTalkSomething'
import WeGetYouHigherRevenues from '../Common/WeGetYouHigherRevenues'
import HowWeWorks from '../Common/HowWeWorks'
import ATrustedBrands from '../Services/ATrustedBrands'
import ContentWritingPricing from '../Common/ContentWritingPricing'
import FrequentlyAskedQuestions from '../Common/FrequentlyAskedQuestions'

export default {
    components: {
        Header,
        PageTitle,
        ListsOfContent,
        LetsTalkSomething,
        WeGetYouHigherRevenues,
        HowWeWorks,
        ATrustedBrands,
        ContentWritingPricing,
        FrequentlyAskedQuestions,
    }
}
</script>