<template>
    <div class="get-started-area bg-main-color pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="get-started-image">
                        <img src="../../assets/images/woman2.png" alt="image">
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="get-started-content white-color">
                        <span class="sub-title">READY TO STARTED?</span>
                        <h2>Let’s Talk Something <br>Amazing Together</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        <router-link to="/contact" class="default-btn style-two">
                            <span class="ri-arrow-right-s-line"></span>
                            Get Started Now
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LetsTalkSomething'
}
</script>