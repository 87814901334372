<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Meet Our Leadership Team" pageTitle="Team" />
        <MeetOurLeadershipPreparing />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import MeetOurLeadershipPreparing from '../Team/MeetOurLeadershipPreparing'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitle,
        MeetOurLeadershipPreparing,
        LetsTalkSomething,
    }
}
</script>