<template>
    <div class="features-style-area pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-card text-start">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/features/features-1.png" alt="image">
                        </div>
                        <h3>Quick Data Analysis</h3>
                        <p>Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Donec rutrum congue leo eget malesuada. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-card text-start">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/features/features-2.png" alt="image">
                        </div>
                        <h3>Secure And Reliable</h3>
                        <p>Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Donec rutrum congue leo eget malesuada. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-card text-start">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/features/features-3.png" alt="image">
                        </div>
                        <h3>Open And Transparent</h3>
                        <p>Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Donec rutrum congue leo eget malesuada. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>