<template>
    <div>
        <Header />
        <PageTitle pageMainTitle="Our Latest Blog" pageTitle="Blog" />
        <BlogContet />
        <LetsTalkSomething />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Common/PageTitle'
import BlogContet from '../Blog/BlogContet'
import LetsTalkSomething from '../Common/LetsTalkSomething'

export default {
    components: {
        Header,
        PageTitle,
        BlogContet,
        LetsTalkSomething,
    }
}
</script>