<template>
    <div class="platforms-area pb-70">
        <div class="container">
            <div class="section-title with-max-width">
                <h2>Our Platforms</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="platforms-item">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/platforms/platforms-1.png" alt="image">
                        </div>
                        <h3>NFT Marketplace</h3>

                        <div class="arrow-image">
                            <img src="../../assets/images/cryptocurrency-home/platforms/arrow-1.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="platforms-item">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/platforms/platforms-2.png" alt="image">
                        </div>
                        <h3>Exchange</h3>

                        <div class="arrow-image">
                            <img src="../../assets/images/cryptocurrency-home/platforms/arrow-2.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="platforms-item">
                        <div class="image">
                            <img src="../../assets/images/cryptocurrency-home/platforms/platforms-3.png" alt="image">
                        </div>
                        <h3>Blockchain</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Platforms'
}
</script>